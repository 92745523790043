import React from 'react'
import Layout from '../components/layout'
import phone from '../images/contact.jpg'
import SEO from '../components/seo'



class Contact extends React.Component {
    render(){
        return (
    
    <Layout>
      <SEO title="Contact"/>
        <div id="hero" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url(${phone})`}}></div>
        <div className="contact-page">
        <h2>Ready to take the next step in getting your project started?</h2>
    <p>Fill out the contact form below or feel free to give us a call at (614)-207-9140.</p>
    <form className="contact-form" method="post" action="https://getform.io/f/a83198ed-4e6d-4250-b4f8-f4ad5e80bec4">
      <label>
        Name*
        <input type="text" name="name" id="name" required/>
      </label>
      <label>
        Email*
        <input type="email" name="email" id="email" required/>
      </label>
      <label>
        Phone Number
        <input type="phone" name="phone" id="phone" />
      </label>
      <label>
        Subject*
        <input type="text" name="subject" id="subject" required/>
      </label>
      <label>
        Message*
        <textarea name="message" id="message" rows="5" required/>
      </label>
      <button type="submit">SUBMIT</button>
     
    </form>
    </div>
    </Layout>
        )}
}
  
  export default Contact;